.intercom-support-container {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo-text {
  font-size: 24px;
  font-weight: bold;
}

.main-content {
  max-width: 600px;
  margin: 0 auto;
}

.main-heading {
  font-size: 28px;
  margin-bottom: 10px;
}

.subheading {
  font-size: 18px;
  margin-bottom: 20px;
}

.intercom-signin-button,
.upload-file-button,
.next-button,
.skip-button {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 10px auto;
  padding: 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.intercom-signin-button {
  background-color: black;
  color: white;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.upload-button .icon {
  margin-right: 5px;
}

.help-link {
  display: block;
  margin: 10px auto;
  font-size: 14px;
  text-decoration: none;
  color: blue;
}

.help-link:hover {
  text-decoration: underline;
}

.next-button {
  background-color: #28a745;
  color: white;
}

.skip-button {
  background-color: transparent;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  padding: 0;
}

.skip-button:hover {
  text-decoration: none;
}
