/* StripeStyles.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background: #f4f4f4;
}

.stripe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.05);
}

.stripe-card {
  text-align: center;
  background: #ffffff;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.stripe-icon {
  font-size: 50px;
  margin-bottom: 20px;
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.success-icon {
  color: #4caf50; /* Green for success */
  border: 2px solid #4caf50;
}

.cancel-icon {
  color: #f44336; /* Red for cancel */
  border: 2px solid #f44336;
}

.stripe-btn {
  background-color: #007bff;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.stripe-btn:hover {
  background-color: #0056b3;
}
