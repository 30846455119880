body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
}

.site-layout-background {
  background: #fff;
  flex: 1;
}

.content {
  margin: 0 16px;
  flex: 1;
}

.content-background {
  padding: 24px;
  min-height: 360px;
  flex: 1;
}

.footer {
  text-align: center;
}
